import { PreloaderBase } from "./preloader-base";
import {
    PreloaderClassSelector,
    PreloaderFailedClassSelector,
    PreloaderElasticEvent,
} from "./models/enums/consts";

export abstract class PreloaderFailed extends PreloaderBase {
    constructor() {
        super();
    }

    public finish = (notifyElastic: boolean = true): void => {
        this.clearEvents();

        this.showError(PreloaderClassSelector.MainLoader);

        if (notifyElastic) {
            const notifyElasticModel = {
                duration: this.getPerformanceDuration(),
                event: PreloaderElasticEvent.FirstHitErrorShown,
            };

            this.notifyElastic(notifyElasticModel);
        }

        this.clearInitialPerformanceWatch();

        this.dispatchEvent(true);
    };

    public showErrorScreen = (): HTMLElement => {
        const loaderContent = preloaderHtmlContent.trim();
        let errorContainer = document.querySelector(
            PreloaderFailedClassSelector.ErrorContainer
        );
        if (!errorContainer) {
            errorContainer = document.createElement("div");
            errorContainer.classList.add(
                PreloaderFailedClassSelector.ErrorContainer.substring(1)
            );
        }

        errorContainer.innerHTML = loaderContent;

        document.body.prepend(errorContainer);

        this.showError(PreloaderFailedClassSelector.ErrorContainer);

        return <HTMLElement>errorContainer;
    };

    public removeErrorScreen = (): void => {
        const container = <HTMLElement>(
            document.querySelector(PreloaderFailedClassSelector.ErrorContainer)
        );

        if (!!container) container.remove();
    };

    protected showError(classSelector): void {
        this.setStyleDisplay(classSelector, "");
        this.setStyleDisplay(PreloaderFailedClassSelector.ErrorText, "");

        document
            .querySelector(PreloaderFailedClassSelector.Retry)
            .addEventListener("click", this.reloadPage);

        this.setStyleDisplay(PreloaderFailedClassSelector.LoadingText, "none");
    }

    protected setStyleDisplay(classSelector: string, display: string): void {
        (<HTMLElement>(
            document.querySelector(classSelector)
        )).style.display = display;
    }

    protected reloadPage(): void {
        window.location.reload();
    }
}
