export enum PreloaderType {
    Preloader = 'Preloader',
    ContentPreloader = 'ContentPreloader',
    Dispatcher = 'Dispatcher',
    Static = 'Static',
}

export enum Preloader888Class {
    LoaderSVG = 'loaderSVG',
    Loader = 'loaderSelector',
    AnimationStopped = 'animationStopped',
    RemovePath = 'removePath',
}

export enum PreloaderBouncingBallClassSelector {
    Spinner = '.loader-spinner',
}

export enum Preloader888ClassSelector {
    Loader = '.loaderSelector',
}

export enum PreloaderFailedClassSelector {
    ErrorContainer = '.error-container',
    LoadingText = '.loading-text',
    ErrorText = '.error-text',
    Retry = '.retry',
}

export enum PreloaderElasticEvent {
    FirstHitErrorShown = 'FirstHitErrorShown',
    PreloaderHide = 'PreloaderHide',
}

export enum PreloaderDispatchEvent {
    Finish = 'preloader-finished-event',
    Cancel = 'preloader-canceled-event',
}

export enum PreloaderPerformanceWatch {
    PreloaderInitial = 'preloaderInitial',
}

export enum PreloaderClass {
    MainLoader = 'main-loader',
    NoScroll = 'preloader-no-scroll',
    StaticLoader = 'splash-brand',
    StaticLoaderInner = 'splash-brand-inner',
}

export enum PreloaderClassSelector {
    MainLoader = '.main-loader',
    StaticLoader = '.splash-brand',
}

export enum PreloaderTheme {
    Default = 'Default',
    SportsIllustrated = 'SportsIllustrated',
}
