import { PreloaderFailed } from "./preloader-failed";

export class PreloaderMissing extends PreloaderFailed {
    constructor() {
        super();
    }

    public init(): void {

    }

    public setWatchEvents = (watchEvents: Array<any>): void => {

    };

    public onEventFinished = (event: string, eventResponseData: any): void => {

    };

    public getDuration = () => {
        return null;
    };

    protected getPerformanceDuration = (): number => {

        return null;
    };


    public cancel = (): void => {

    };

    public finish = (notifyElastic: boolean = true): void => {

    };

    public remove = (): void => {
    };

    protected clearEvents(): void {

    }

    protected startPerformanceWatch(name): void {
    }

    protected clearPerformanceWatch(performanceWatch: string): void {
    }

    protected clearInitialPerformanceWatch(): void {
    }

    protected notifyElastic(elasticData): void {
    }

    protected dispatchEvent(
        isFinishedEvent: boolean,
        eventData: any = null
    ): void {

    }

    public showErrorScreen = (): HTMLElement => {
        return null;
    };

    public removeErrorScreen = (): void => {

    };

    protected showError(classSelector): void {

    }

    protected setStyleDisplay(classSelector: string, display: string): void {

    }
}
