import './preloader.css';

import { PreloaderType } from './models/enums/consts';
import { PreloaderMissing } from './preloader-missing';
import { StaticPreloader } from './preloader-static';

let preloader;

const setPreloader = () => {
    const serverPreloaderType = preloaderType?.trim() || '';

    if (!serverPreloaderType) console.error('[Preloader] - Failed to load preloader settings');

    switch (serverPreloaderType) {
        case PreloaderType.Static:
            preloader = new StaticPreloader();
            break;
        default:
            preloader = new PreloaderMissing();
    }
};

setPreloader();

export const {
    onEventFinished,
    isSlowConnection,
    showErrorScreen,
    setWatchEvents,
    getDuration,
    removeErrorScreen,
    remove,
    finish,
    cancel,
} = preloader;
